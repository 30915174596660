@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"), url(./fonts/Montserrat.ttf) format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Montserrat";
	src: url(./fonts/Montserrat-Thin.ttf) format("truetype");
	font-weight: 100;
}

@font-face {
	font-family: "Montserrat";
	src: url(./fonts/Montserrat-Light.ttf) format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: "Montserrat";
	src: url(./fonts/Montserrat-Regular.ttf) format("truetype");
	font-weight: 400;
}

@font-face {
	font-family: "Montserrat";
	src: url(./fonts/Montserrat-Medium.ttf) format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "Montserrat";
	src: url(./fonts/Montserrat-SemiBold.ttf) format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Montserrat";
	src: url(./fonts/Montserrat-Bold.ttf) format("truetype");
	font-weight: 700;
}

@font-face {
	font-family: "UKNumberPlate";
	src: url(./fonts/UKNumberPlate.ttf) format("truetype");
	font-weight: 700;
}

html,
body {
	background-color: #bfeaf1;
	font-size: 16px;
	font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
	color: #fff;
	background: #ddf1f6;
	overflow-x: hidden;
}

h1 {
	font-weight: bold;
	font-size: 2em;
	margin-bottom: 20px;
}

h2 {
	font-weight: bold;
	font-size: 1.6em;
}

li {
	font-weight: 600;
}

a {
	color: #29b9dd;
}

a:hover {
	color: #29b9dd;
	cursor: "pointer";
}

i {
	font-style: normal;
}

.container {
	color: #fff;
}

.submit {
	background-color: #fff;
	border: 2px solid #fff;
	color: #2ebfe3;

	font-weight: bold;
}

.submit:hover {
	background-color: #2e7ea3;
	border: 2px solid #fff;
	color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
}

.registration {
	background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
	padding: 0.25em 1em 0.25em 1em;
	font-weight: bold;
	font-size: 2em;
	border-radius: 5px;
	border: 1px solid #000;
	box-shadow: 1px 1px 1px #ddd;
	position: relative;
}

.registration-plate {
	font-family: UKNumberPlate;
	text-transform: uppercase;
}

.vehicle-details {
	background: none;
	margin: 2rem 0;
}

.vehicle-details-column {
	border-bottom: 1px solid #bfddf3;
	/* width: 80%; */
}

.vehicle-details .vehicle-details-contents {
	/* border-bottom: 1px solid #bfddf3; */
	/* text-align: left; */
}

.vehicle-details-header {
	display: block;
	font-size: 1rem;
	color: #000;
}

.vehicle-details-value {
	display: inline-block;
	font-weight: 900;
	font-size: 1rem;
}

option {
	color: #000;
}

optgroup {
	color: #000;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

/*
Overrides
*/
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: #fff;
}

.dropdown-menu {
	border-radius: 20px;
}

.dropdown-item,
.dropdown-menu {
	background-color: #1f1d49;
	padding: 1rem;
	color: #fff;
}

.dropdown-item:hover {
	border-radius: 20px;
	background: #2ebfe3;
	color: #fff;
	font-weight: normal;
}

.dropdown-toggle::after {
	display: none;
}

@media (max-width: 576px) {
	#basic-navbar-nav {
		background: #1f1d49;
		border-radius: 20px;
		z-index: 1001;
		padding-top: 10px;
	}
	#basic-navbar-nav a {
		margin-left: 0;
	}
}

@media (max-width: 426px) {
	footer {
		display: block !important;
		position: unset !important;
	}
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
	left: 30%;
}

.btn {
	background-color: #fff;
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
	color: #1e1d33;
	background-color: transparent;
}

.fc-event-title {
	color: #1e1d33;
}

.fc-toolbar-chunk .bi {
	color: #fff;
}

.fc-event-main {
	border: 1px solid #2ebfe3;
}

.fc-h-event {
	border: 1px solid #2ebfe3;
}

.fc-event-title-container {
	background: #2ebfe3;
	padding: 5px;
	font-size: 1.2em;
	font-weight: bold;
}

.btn-primary {
	background-color: #29b9dd;
	border: none;
	color: #fff;
	border-radius: 20px;
	width: 100%;
}

.btn-primary:hover {
	border: none;
}

.btn-primary:disabled {
	background-color: #29b9dd;
}

.btn-secondary {
	background: #dda729;
	color: #1f1d49;
	width: 100%;
}

.btn-secondary:hover {
	background: #ab811f;
	color: #1f1d49;
}

.selectField {
	border-radius: 10px;
}

.accordion-button {
	color: #ffffff;
	background-color: #29b9dd;
	box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
	font-weight: bold;
}

.accordion-button:not(.collapsed) {
	color: #29b9dd;
	background-color: #ffffff;
	box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
	font-weight: bold;
}

.progress-bar {
	background-color: #29b9dd;
}

.altText {
	color: #1f1d4c !important;
}

.loadingText {
	color: #29b8d0 !important;
}

.invalid-feedback {
	color: #e62e39;
}

.rounded {
	border-radius: 10px !important;
}

.nav-link.active > .nav-item span {
	border-bottom: solid 3px #2ebfe3;
	padding-bottom: 15px;
}

.alert-danger {
	border-radius: 10px;
	border: none;
	color: #e62e39;
	text-align: center;
}

.carscore-lozenge {
	background-color: #2ebfe3;
	border-radius: 10px;
}

.carscore-lozenge h5 {
	text-decoration: underline;
	text-underline-offset: 5px;
	margin-bottom: 10px;
}

.information-lozenge {
	background-color: #1f1d49;
	border-radius: 25px;
}

.modal-content {
	border: none;
	border-radius: 20px;
	background-color: white;
	color: black;
}

.modal-body aside {
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}

.form-check {
	display: flex;
	align-items: center;
}

.form-check-input {
	width: 1.4em;
	height: 1.4em;
	margin-right: 15px;
	margin-top: 0;
}

.form-check-input:checked {
	background-color: #29b9dd;
	border-color: #29b9dd;
}

.red-dashed-line {
	--s: 10px;
	width: 5px;
	height: 200px;
	background: radial-gradient(circle closest-side, #dc3545 98%, #0000) 0 0/100% var(--s), linear-gradient(#dc3545 50%, #0000 0) 0 calc(var(--s) / 2) / 100% calc(2 * var(--s));
}

input.otp-input::-webkit-outer-spin-button,
input.otp-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input.otp-input[type="number"] {
	-moz-appearance: textfield;
}
