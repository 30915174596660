@keyframes cloud1 {
	0% {
		left: 0%;
	}
	25% {
		left: 15%;
	}
	50% {
		left: 30%;
	}
	75% {
		left: 15%;
	}
	100% {
		left: 0%;
	}
}

@keyframes cloud2 {
	0% {
		right: 0%;
	}
	25% {
		right: 25%;
	}
	50% {
		right: 65%;
	}
	75% {
		right: 30%;
	}
	100% {
		right: 0%;
	}
}

.cloudOne {
	position: absolute;
	background: url("../../public/img/splash/cloud-3.svg") no-repeat;
	height: 22%;
	width: 31%;
	top: 60%;
	left: -5%;
	background-size: contain;
	transition: left 1s linear;
	z-index: 0;
	/* animation: cloud1 linear infinite alternate 70s; */
}

.cloudTwo {
	position: absolute;
	background: url("../../public/img/splash/cloud-2.svg") no-repeat;
	height: 20%;
	width: 20%;
	bottom: 15%;
	right: 27%;
	background-size: contain;
	transition: right 1s linear;
	/* animation: cloud2 linear infinite alternate 70s; */
}

.cloudThree {
	position: absolute;
	background: url("../../public/img/splash/cloud-3.svg") no-repeat;
	top: 40%;
	height: 22%;
	width: 31%;
	right: 0;
	/* bottom: -25%; */
	background-size: contain;
	transition: right 1s linear;
	/* animation: cloud2 linear infinite alternate 70s; */
}

.cloudFour {
	position: absolute;
	background: url("../../public/img/splash/cloud-1.svg") no-repeat;
	height: 35%;
	width: 35%;
	top:10%;
	/* bottom: -45%; */
	background-size: contain;
	transition: left 1s linear;
	/* animation: cloud1 linear infinite alternate 70s; */
}
